<template>
  <div class="home">
    <div class="square-container">
      <!-- Génération dynamique des carrés -->
      <div 
        v-for="(item, index) in squares" 
        :key="index" 
        class="square"
      >
        <router-link :to="item.link">
          <div class="square-content">
            <div class="title">{{ item.title }}</div>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script src="./home.ts"></script>
<style src="./home.css"></style>
