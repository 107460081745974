<template>
  <div>
    <router-link to="/">
      <button class="gold-button">Accueil</button>
    </router-link>

    <!-- Affichage si les inscriptions sont clôturées -->
    <div v-if="registrationClosed">
      <h1>Les inscriptions par le site sont clôturées. Si il reste de la place, rendez-vous sur place au moins 1 heure avant le début du tableau.</h1>
    </div>

    <!-- Formulaire d'inscription -->
    <div v-else>
      <div v-if="inscriptionDone">
        <h1>Inscription réalisée avec succès : un email récapitulatif vous a été envoyé. (Vérifiez les spams)</h1>
      </div>

      <div v-else>
        <p>Entrez votre numéro de licence</p>
        <input class="styled-input" type="number" v-model="licenceNumber" placeholder="Numéro de licence" min="0"
          @keyup.enter="getLicenceJoueur" @input="updateLicenceNumber" />
        <button class="gold-button" @click="getLicenceJoueur" :disabled="!licenceNumber">
          Valider
        </button>
        <button class="gold-button" @click="annuler" :disabled="!isLicenceEntered">Annuler</button>

        <!-- Infos joueur -->
        <div v-if="joueurInfo && joueurInfo.liste.licence">
          <h2>Informations sur le joueur</h2>
          <p>Numéro de licence : {{ joueurInfo.liste.licence[0].licence[0] }}</p>
          <p>Nom : {{ joueurInfo.liste.licence[0].nom[0] }}</p>
          <p>Prénom : {{ joueurInfo.liste.licence[0].prenom[0] }}</p>
          <p>Club : {{ joueurInfo.liste.licence[0].nomclub[0] }}</p>
          <p>Catégorie : {{ joueurInfo.liste.licence[0].cat[0] }}</p>
          <p>Classement officiel : {{ joueurInfo.liste.licence[0].point[0] }}</p>
          <!-- Conteneur pour l'email -->
         
        <div class="form-group">
          <label for="email">E-mail : </label>
          <input class="styled-input" type="text" id="email" placeholder="Email" v-model="userEmail"
            @input="validateEmail" />
          <p v-if="invalidEmail" class="error-message">L'adresse e-mail n'est pas valide.</p>
        </div>
        
        <!-- Conteneur pour le numéro de téléphone -->
        <div class="form-group">
          <label for="phone">Numéro de téléphone : </label>
          <input class="styled-input" type="text" id="phone" placeholder="Numéro de téléphone" v-model="userPhoneNumber"
            @input="validatePhoneNumber" />
          <p v-if="invalidPhoneNumber" class="error-message">Le numéro de téléphone n'est pas valide.</p>
        </div>
        </div>

        <p class="error-message" v-if="!isNumLicenceValid">Numéro de licence incorrect</p>
        <p class="error-message" v-if="alreadySigned">Joueur déjà inscrit</p>
        <div v-if="showTables">
        <h2>Liste des tableaux</h2>
        <h3>Samedi</h3>
        <ul>
          <li v-for="table in groupTableSaturday" :key="table.id" :class="{
      strikethrough:
        joueurInfo.liste.licence[0].point[0] > table.maxRank || table.placesNumber <= 0 ||
        (table.sex !== null &&
          joueurInfo.liste.licence[0].sexe[0] !== table.sex) ||
        (table.name === 'Féminines' &&
          joueurInfo.liste.licence[0].sexe[0] !== 'F') ||
        (table.name === 'Benjamins / Minimes' &&
          !['Minimes', 'Benjamins'].some(cat => joueurInfo.liste.licence[0].cat[0].includes(cat))),
    }">
            <label class="custom-checkbox">
              <input type="checkbox" :id="table.id" v-model="selectedTablesSaturday" :value="table.id"
                @click="handleCheckboxClick(selectedTablesSaturday, maxSelectedPerList, joueurInfo.liste.licence[0].sexe[0], table.id, table.name)"
                :disabled="joueurInfo.liste.licence[0].point[0] > table.maxRank || table.placesNumber <= 0 ||
      (table.sex !== null &&
        joueurInfo.liste.licence[0].sexe[0] !== table.sex) ||
      (table.name === 'Féminines' &&
        joueurInfo.liste.licence[0].sexe[0] !== 'F') ||
      (table.name === 'Benjamins / Minimes' &&
        !['Minimes', 'Benjamins'].some(cat => joueurInfo.liste.licence[0].cat[0].includes(cat)))
      " />
              <span class="checkmark"></span>
              {{ table.name }} - Nombre de places : {{ table.placesNumber }} - {{ table.startHour }}
            </label>
          </li>
        </ul>
        <h3>Dimanche</h3>
        <ul>
          <li v-for="table in groupTableSunday" :key="table.id" :class="{
      strikethrough:
        joueurInfo.liste.licence[0].point[0] > table.maxRank || table.placesNumber <= 0 ||
        (table.sex !== null &&
          joueurInfo.liste.licence[0].sexe[0] !== table.sex) ||
        (table.name === 'Féminines' &&
          joueurInfo.liste.licence[0].sexe[0] !== 'F') ||
        (table.name === 'Benjamins / Minimes' &&
          !['Minimes', 'Benjamins'].some(cat => joueurInfo.liste.licence[0].cat[0].includes(cat))),
    }">
            <label class="custom-checkbox">
              <input type="checkbox" :id="table.id" v-model="selectedTablesSunday" :value="table.id"
                @click="handleCheckboxClick(selectedTablesSunday, maxSelectedPerList, joueurInfo.liste.licence[0].sexe[0], table.id, table.name)"
                :disabled="joueurInfo.liste.licence[0].point[0] > table.maxRank || table.placesNumber <= 0 ||
      (table.sex !== null &&
        joueurInfo.liste.licence[0].sexe[0] !== table.sex) ||
      (table.name === 'Féminines' &&
        joueurInfo.liste.licence[0].sexe[0] !== 'F') ||
      (table.name === 'Benjamins / Minimes' &&
        !['Minimes', 'Benjamins'].some(cat => joueurInfo.liste.licence[0].cat[0].includes(cat)))
      " />
              <span class="checkmark"></span>
              {{ table.name }} - Nombre de places : {{ table.placesNumber }} - {{ table.startHour }}
            </label>
          </li>
        </ul>
        <button class="gold-button" :disabled="invalidEmail || invalidPhoneNumber ||
      userEmail == '' || userPhoneNumber == '' ||
      (selectedTablesSaturday.length == 0 && selectedTablesSunday.length == 0) || isSubmitting
      " @click="validateInscription">
          Valider l'inscription
        </button>
      </div>
      </div>
    </div>
  </div>
</template>

<script src="./registration.ts"></script>
<style src="./registration.css"></style>