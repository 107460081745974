
vue
Copier
Modifier
<template>
  <div>
    <!-- Bouton Accueil -->
    <router-link to="/">
      <button class="gold-button">Accueil</button>
    </router-link>

    <!-- Conteneur principal -->
    <div class="global-container">
      <!-- Image cliquable -->
      <div>
        <img 
          class="clickable-image" 
          src="@/assets/Affiche_tournoi_national.jpg" 
          alt="Affiche Tournoi National" 
        />
      </div>

      <!-- Conteneur PDF -->
      <div class="pdf-container">
        <a :href="pdfUrl">
          <button class="gold-button">
            Télécharger le règlement
          </button>
        </a>
        <!-- PDF intégré pour non-mobile -->
        <object 
          v-if="!isMobile" 
          :data="pdfUrl" 
          type="application/pdf" 
          class="pdf-viewer">
        </object>
      </div>
    </div>
  </div>
</template>

<script src="./rules.ts"></script>
<style src="./rules.css"></style>