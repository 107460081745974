
import axios from 'axios';
import { defineComponent } from "vue";
import { Table } from '@/interfaces/tableInterface';
import { Player } from '@/interfaces/playerInterface';
import { PlayerTable } from '@/interfaces/playerTableInterface';
import { Admin } from '@/interfaces/adminInterface';
import { useRouter } from 'vue-router';

const apiUrl = process.env.VUE_APP_API_BASE_URL; // When testing on mobile

export default defineComponent({
    data() {
        return {
            players: [] as Player[],
            tables: [] as Table[],
            groupTableSaturday: [] as Table[],
            groupTableSunday: [] as Table[],
            playerTableData: [] as PlayerTable[],
            admin: [] as Admin[],
            adminIsOk: false,
            upButton: false,
        };
    },
    created() {
        this.admin = JSON.parse(localStorage.getItem('admin') || 'null');
        if (this.admin != null) {
            this.adminIsOk = true;
            this.getAllPlayers();
            this.getAllTables();
            this.getAllPlayerTable();
        }
        else {
            const router = useRouter();
            router.push('/admin-login');
        }
    },
    methods:
    {
        async getAllPlayers() {
            try {
                const response = await axios.get(`${apiUrl}/getAllPlayers`);
                this.players = response.data;
            } catch (error) {
                console.error('Erreur lors de la récupération des joueurs :', error);
            }
        },
        getPlayerName(playerId: number) {
            const player = this.players.find(player => player.id === playerId);
            return player ? `${player.name} ${player.surname}` : '';
        },
        getPlayerClub(playerId: number) {
            const player = this.players.find(player => player.id === playerId);
            return player ? player.club : '';
        },
        getPlayerRank(playerId: number) {
            const player = this.players.find(player => player.id === playerId);
            return player ? player.rank : '';
        },
        getPlayerMail(playerId: number) {
            const player = this.players.find(player => player.id === playerId);
            return player ? player.mail : '';
        },
        getPlayerPhoneNumber(playerId: number){
            const player = this.players.find(player => player.id === playerId);
            return player ? player.phone : '';
        },
        async getAllPlayerTable() {
            try {
                this.playerTableData = [];
                const response = await axios.get<PlayerTable[]>(`${apiUrl}/getAllPlayerTable`);
                this.playerTableData = response.data;
                this.sortPlayersByRank();
            } catch (error) {
                console.error('Erreur lors de la récupération des tableaux et des joueurs :', error);
            }
        },
        async deletePlayer(playerId: any) {
            try {
                await axios.delete(`${apiUrl}/deletePlayer/${playerId}`);
            } catch (error) {
                console.error("Erreur lors de la suppression du joueur :", error);
            }
        },
        async getAllTables() {
            try {
                this.tables = [];
                this.groupTableSaturday = [];
                this.groupTableSunday = [];
                const response = await axios.get<Table[]>(`${apiUrl}/getAllTables`);
                this.tables = response.data;
                this.tables.forEach(table => {
                    if (table.day == 'S') {
                        this.groupTableSaturday.push(table);
                    }
                    if (table.day == 'D') {
                        this.groupTableSunday.push(table);
                    }
                });
            } catch (error) {
                console.error('Erreur lors de la récupération des tableaux :', error);
            }
        },
        async deletePlayerTable(playerTable: any, table: any) {
            try {
                await axios.delete(`${apiUrl}/deletePlayerTable/`, {
                    params: {
                        playerId: playerTable.playerId,
                        tableId: table.id
                    }
                });
                const playerEmail = this.getPlayerMail(playerTable.playerId);
                const tableName = table.name;
                await this.sendUnsuscribeMail(playerEmail, tableName);
                await this.updatePlacesNumber(table.id);
                const index = this.playerTableData.indexOf(playerTable);
                if (index !== -1) {
                    this.playerTableData.splice(index, 1);
                }

                // Ajouter un délai d'attente de 1 seconde (ou le nombre de millisecondes souhaité)
                setTimeout(async () => {
                    // Actualisez les données après le délai
                    await this.getAllTables();
                    await this.getAllPlayerTable();

                    // Vérifier si le playerId existe encore dans playerTableData
                    const playerExists = this.playerTableData.some(pt => pt.playerId === playerTable.playerId);
                    if (!playerExists) {
                        // Si le playerId n'existe plus, supprimer le joueur
                        await this.deletePlayer(playerTable.playerId);
                    }

                }, 1000); // 1000 millisecondes = 1 seconde
            } catch (error) {
                console.error("Erreur lors de la suppression de la ligne PlayerTable :", error);
            }
        },
        async updatePlacesNumber(tableIdToIncreasePlaces: number) {
            try {
                await axios.put(`${apiUrl}/increaseTablePlaces/${tableIdToIncreasePlaces}`);
            } catch (error) {
                console.error(error);
            }
        },
        sortPlayersByRank() {
            this.playerTableData.sort((a, b) => {
                const playerARank = this.getPlayerRank(a.playerId);
                const playerBRank = this.getPlayerRank(b.playerId);

                if (typeof playerARank === 'number' && typeof playerBRank === 'number') {
                    return playerBRank - playerARank;
                }

                if (typeof playerARank === 'number') {
                    return -1; // Player A is a number, so it should come first
                }

                if (typeof playerBRank === 'number') {
                    return 1; // Player B is a number, so it should come first
                }

                // Both player ranks are not numbers, compare them as strings
                return (playerBRank as string).localeCompare(playerARank as string);
            });
        },
        async sendUnsuscribeMail(receiverMail: string, tableForMessage: any) {
            try {
                const tableHtml = `<li>Tableau : ${tableForMessage}</li>`;
                const response = await axios.post(`${apiUrl}/sendUnsuscribeEmail`, { receiverMail, tableHtml });
                console.log('E-mail envoyé avec succès :', response.data);
            } catch (error) {
                console.error('Erreur lors de l\'envoi de l\'e-mail :', error);
            }
        },
        // Fonction pour exporter les données en Excel
        async exportToExcel() {
            try {
                // Appel de l'API pour obtenir le fichier Excel
                const response = await axios.get(`${apiUrl}/export-to-excel`, {
                    responseType: 'blob', // Important pour le téléchargement de fichiers
                });

                // Créer un lien pour télécharger le fichier
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const a = document.createElement('a');
                a.href = url;
                a.download = 'Joueurs_Tournoi.xlsx';
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
            } catch (error) {
                console.error('Erreur lors de l\'exportation :', error);
            }
        },
        handleScroll(){
            // Rendre le bouton visible si l'utilisateur défile de plus de 300px
            this.upButton = window.scrollY > 300;
        } ,
        goUp() {
            // Remonter doucement en haut de la page
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
        },
    },
    mounted() {
        window.addEventListener('scroll', this.handleScroll);
    },
    beforeUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    },
});