<template>
  <div id="app">
    <Header />
      <main class="main-content">
        <Home />
        <router-view />
      </main>
      <Footer />
  </div>
</template>

<script>
import Header from './components/header/Header.vue';
import Home from './components/home/Home.vue';
import Footer from './components/footer/Footer.vue';

export default {
  components: {
    Header,
    Footer
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
body{
  margin: 0;
}
  /* Structure globale */
  html, body, #app {
    margin: 0;
    padding: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
</style>