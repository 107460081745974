import { defineComponent } from "vue";

export default defineComponent({
    data() {
        return {
            squares: [
                { title: "Inscription", link: "/registration" },
                { title: "Liste des inscrits", link: "/playerslist" },
                { title: "Règlement", link: "/rules" },
              ],
        };
    },
});
