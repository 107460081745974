<template>
  <footer class="footer">
    <div class="footer-images">
      <!-- Icône Facebook -->
      <a 
        href="https://www.facebook.com/profile.php?id=61553582158272" 
        target="_blank" 
        rel="noopener" 
        aria-label="Facebook">
        <img src="@/assets/facebookIcon.png" alt="Facebook" />
      </a>

      <!-- Bouton Administration -->
      <router-link to="/admin-login" aria-label="Administration">
        <button class="gold-button-admin">Administration</button>
      </router-link>

      <!-- Icône Instagram -->
      <a 
        href="https://www.instagram.com/a.t.t.la_raquette_d_or/" 
        target="_blank" 
        rel="noopener" 
        aria-label="Instagram">
        <img src="@/assets/instagramIcon.png" alt="Instagram" />
      </a>
    </div>
    <p>&copy; 2024 - DUVAL Elouan - Tous droits réservés</p>
  </footer>
</template>

<script src="./footer.ts"></script>
<style src="./footer.css"></style>
