import { defineComponent, ref, onMounted, onBeforeUnmount } from "vue";
import pdfFile from "@/assets/reglement_tournoi_att_raquette_d_or.pdf";

export default defineComponent({
  name: "Rules",
  setup() {
    const pdfUrl = pdfFile;
    const isMobile = ref(window.innerWidth <= 768);

    // Fonction pour mettre à jour l'état mobile lors du redimensionnement de la fenêtre
    const updateIsMobile = () => {
      isMobile.value = window.innerWidth <= 768;
    };

    // Ajouter et supprimer l'écouteur d'événements pour la gestion de la taille de l'écran
    onMounted(() => window.addEventListener("resize", updateIsMobile));
    onBeforeUnmount(() => window.removeEventListener("resize", updateIsMobile));

    return { pdfUrl, isMobile };
  },
});
