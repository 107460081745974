<template>
  <div>
    <router-link to="/">
      <button class="gold-button">Accueil</button>
    </router-link>
    <!-- Contenu de la vue Home -->
    <h1>Page non existante</h1>
  </div>
</template>

<script src="./notFound.ts"></script>
<style src="./notFound.css"></style>

