<template>
  <header class="header">
    <div class="logo">
      <router-link to="/">
        <img src="@/assets/LogoRAQOR.png" alt="Logo de RAQUETTE D'O.R. A.T.T." />
      </router-link>
    </div>
  </header>
</template>

<script src="./header.ts"></script>
<style src="./header.css"></style>
